import React from 'react'
import { Link } from 'react-router-dom'

// Img
import windows from "../img/icons/windows.png"
import appstore from "../img/icons/apple.png"
import playstore from "../img/icons/playstore.png"

export default function AppIcons() {
  return (
    <div className="app-store-bx">
        <Link to="https://download.mql5.com/cdn/web/airrsan.fx.ltd/mt5/airrsanfx5setup.exe" className="asb-item">
            <img src={windows} alt="" />
            <div>
              <div className="asb-label">GET IT FOR</div>
              <div className="asb-heading">Windows</div>
            </div>
        </Link>
        <Link to="https://apps.apple.com/us/app/metatrader-5/id413251709" className="asb-item">
            <img src={appstore} alt="" />
            <div>
              <div className="asb-label">GET IT FOR</div>
              <div className="asb-heading">App Store</div>
            </div>
        </Link>
        <Link to="https://download.mql5.com/cdn/mobile/mt5/android?server=AirrsanFX-Live" className="asb-item">
            <img src={playstore} alt="" />
            <div>
              <div className="asb-label">GET IT FOR</div>
              <div className="asb-heading">Google Play</div>
            </div>
        </Link>
    </div>
  )
}
