import React from 'react'
// Icpns
import Icons from './Icons';

// Stores Icons
import AppIcons from './AppIcons';

// Img
import appbanner from "../img/bgimg/appbanner.webp"

export default function StoreSection() {
  return (
    <div className="relative app-download-bx py-4 px-3 px-lg-5">
        <div className="app-download-inner">
            <div className="common-heading-bx">
                <div className="common-heading" style={{maxWidth:"414px"}}>Trade on the go with AirrsanFx web & mobile app</div>
            </div>

            <div className="check-list-bx">
                <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg /></div> Easy monitoring</div>
                <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg /></div> 24/7 Support</div>
                <div className="clb-item"><div className="clb-item-icon"><Icons.CheckSvg /></div> Multiple payment methods</div>
            </div>

            <AppIcons />
        </div>
        <img data-aos="fade-up" data-aos-delay="400" className="adbbanner-img" src={appbanner} alt='' />
    </div>
  )
}
