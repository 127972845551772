
import React from 'react';
import {Link, NavLink} from "react-router-dom";

// Images Common File
import * as Img from '../Img';

// Css File
import '../../css/sidemenu.css'

// Design Files
import { useMenu } from './MenuContext';
// import Socialicon from '../Socialicon';

export default function Sidemenu() {

    const { isActive, toggleMenu } = useMenu();

    return (
        <>
            <aside className={`side-menu-bx ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
                <div className="smb-logo-bx">
                    <span style={{top:"17px"}} className="close-icon trigger-closed" onClick={toggleMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
                    </span>
                    <Link to="/" className="smb-logo-link">
                        <img className="logo-img" src={Img.logo_anim} alt="" />
                        <img className="icon-anim" src={Img.icon_anim} alt="" />
                    </Link>
                </div>

                <div className="smb-menu-bx">

                    <div className="smb-signinup-btn mt-2">
                        <Link to="https://member.airrsanfx.com/register" target="_blank" className="common-btn-item cbi-outline"><span className="cbidata">Register</span><span>Register</span></Link>
                        <Link to="https://member.airrsanfx.com/login" target="_blank" className="common-btn-item cbi-fill"><span>Login</span></Link>
                    </div>

                    <ul className='pb-0 mt-4'>
                        <div className="smb-nav-lable mt-3 mb-2">Explore</div>
                        <li><NavLink to="/" className="smb-link">Home</NavLink></li>
                        <li><NavLink to="/about" className="smb-link">About Us</NavLink></li>
                        <li><NavLink to="/faqs" className="smb-link">FAQs</NavLink></li>
                        <li><NavLink to="/contact" className="smb-link">Contact Us</NavLink></li>
                    </ul>

                    <ul className='pb-0 mt-4'>
                        <div className="smb-nav-lable mt-3 mb-2">Our Policies</div>
                        <li><NavLink to="/privacy-policy" className="smb-link">Privacy Policy</NavLink></li>
                        <li><NavLink to="/terms-and-conditions" className="smb-link">Terms & Conditions</NavLink></li>
                        <li><NavLink to="/aml-policy" className="smb-link">AML Policy</NavLink></li>
                        <li><NavLink to="/deposit-withdrawal-policy" className="smb-link">Deposit & withdrawal Policy</NavLink></li>
                        <li><NavLink to="/restricted-countries" className="smb-link">Restricted Countries</NavLink></li>
                        <li><NavLink to="/risk-disclosure" className="smb-link">Risk Disclosure</NavLink></li>
                    </ul>

                    <div className="py-5"></div>

                </div>

                {/* <div className="smb-footer">
                    <Socialicon className="social-icon justify-content-center" />
                </div> */}

            </aside>

            <div className={`overlaydiv ${isActive ? 'active' : ''}`} onClick={toggleMenu}></div>
        </>
    );
};



