import React from 'react'
import { Link } from 'react-router-dom'

// Icons File
import Icons from "../components/Icons"

// Css File
import "../css/IndexSection.css"

export default function IndexSection() {
  return (
  <div className="textlayout-main-bx">
      <div data-aos="fade-up" className="tlm-heading">Instant account opening & funding</div>
      <div data-aos="fade-up" data-aos-delay="400" className="tlm-sub-text">Trade within minutes!</div>
      <div data-aos="fade-up" data-aos-delay="500" className="tlm-btns mt-1">
          <Link to="https://member.airrsanfx.com/register" target="_blank" className="common-btn-item cbi-fill"><span>Registration<Icons.RightArrowSvg /></span></Link>
          {/* <div className="common-btn-item cbi-outline"><span className='cbidata'>Funded account</span><span>Open Account</span></div> */}
      </div>
  </div>
  )
}
